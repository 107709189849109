













import Vue from 'vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
export default Vue.extend({
  name: 'InstantFormProcessEnd',
  components: {
    BaseAlert
  },
  data () {
    return {
      campaign: ''
    }
  },
  created () {
    this.campaign = JSON.parse(localStorage.getItem('process_creating_instant_form'))
    const id = this.campaign.campaignId
    localStorage.setItem('process_creating_instant_form', JSON.stringify({ campaignId: id, status: 'end' }))
  }
})
